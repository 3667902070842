import * as Sentry from "@sentry/remix";
import { RemixBrowser, useLocation, useMatches } from "@remix-run/react";
import { hydrate } from "react-dom";
import { useEffect } from "react";
const sentryEnv = window.ENV.SENTRY_ENV;
Sentry.init({
  dsn: "https://a13d7976308a4ae3b01559127b13d121@o1317498.ingest.sentry.io/6571406",
  tracesSampler: ({ transactionContext }) => {
    if (transactionContext.name.endsWith("/health")) {
      return 0;
    }
    return sentryEnv === "production" ? 0.2 : 1;
  },
  environment: sentryEnv,
  integrations: [
    new Sentry.BrowserTracing({
      routingInstrumentation: Sentry.remixRouterInstrumentation(useEffect, useLocation, useMatches),
    }),
  ],
});

hydrate(<RemixBrowser />, document);

// Replace with this when remove all the Material UI from this repo
// We cannot use the following because EMOTION style won't work

// startTransition(() => {
//   hydrateRoot(
//     document,
//     <StrictMode>
//       <RemixBrowser />
//     </StrictMode>,
//   );
// });
